@font-face {
font-family: 'GTAmerica';
src: url(https://static.depop.com/_next/static/media/b980bc92eb41b84f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: 'GTAmerica';
src: url(https://static.depop.com/_next/static/media/ef11c7a677d66bf6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: 'GTAmerica Fallback';src: local("Arial");ascent-override: 97.69%;descent-override: 25.20%;line-gap-override: 0.00%;size-adjust: 102.37%
}.__className_20ee8a {font-family: 'GTAmerica', 'GTAmerica Fallback'
}.__variable_20ee8a {--font-GtAmerica: 'GTAmerica', 'GTAmerica Fallback'
}

@font-face {
font-family: 'GTAmericaExtended';
src: url(https://static.depop.com/_next/static/media/c98f9a11a81db513-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'GTAmericaExtended';
src: url(https://static.depop.com/_next/static/media/7869aaa1a21805cd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: 'GTAmericaExtended Fallback';src: local("Arial");ascent-override: 82.07%;descent-override: 16.33%;line-gap-override: 0.00%;size-adjust: 122.45%
}.__className_4286a4 {font-family: 'GTAmericaExtended', 'GTAmericaExtended Fallback'
}.__variable_4286a4 {--font-GtAmericaExtended: 'GTAmericaExtended', 'GTAmericaExtended Fallback'
}

@font-face {
font-family: 'GTAmericaExpanded';
src: url(https://static.depop.com/_next/static/media/08087eaea0ff3ab8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'GTAmericaExpanded';
src: url(https://static.depop.com/_next/static/media/76c67b3b80994f80-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: 'GTAmericaExpanded Fallback';src: local("Arial");ascent-override: 66.43%;descent-override: 13.22%;line-gap-override: 0.00%;size-adjust: 151.29%
}.__className_27568d {font-family: 'GTAmericaExpanded', 'GTAmericaExpanded Fallback'
}.__variable_27568d {--font-GtAmericaExpanded: 'GTAmericaExpanded', 'GTAmericaExpanded Fallback'
}

